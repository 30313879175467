import { Interface } from '@ethersproject/abi'
import { TransactionResponse } from '@ethersproject/abstract-provider'
import { BigNumber } from '@ethersproject/bignumber'
import { hexlify, hexZeroPad } from '@ethersproject/bytes'
import { t } from '@lingui/macro'
import { useTrace } from '@uniswap/analytics'
import { Currency, CurrencyAmount, Token } from '@uniswap/sdk-core'
import { useWeb3React } from '@web3-react/core'
import { BridgeChainIds, chainPair, L1_CHAIN_IDS, SupportedChainId, SupportedL1ChainId } from 'constants/chains'
import { PermitSignature } from 'hooks/usePermitAllowance'
import { numberToWei } from 'nft/utils'
import { useCallback, useMemo } from 'react'
import { TransactionInfo, TransactionType } from 'state/transactions/types'

import bridgeABI from '../bridge/build/contracts/Bridge.json'
import { useTransactionAdder } from '../state/transactions/hooks'
import { calculateGasMargin } from '../utils/calculateGasMargin'
import { UserRejectedRequestError } from '../utils/errors'
import isZero from '../utils/isZero'
import { didUserReject, swapErrorToUserReadableMessage } from '../utils/swapErrorToUserReadableMessage'
import { useBridgeContract } from './useContract'
import useTransactionDeadline from './useTransactionDeadline'

class GasEstimationError extends Error {
  constructor() {
    super(t`Your swap is expected to fail.`)
  }
}
class ModifiedSwapError extends Error {
  constructor() {
    super(
      t`Your swap was modified through your wallet. If this was a mistake, please cancel immediately or risk losing your funds.`
    )
  }
}

const BridgeInterface: Interface = new Interface(bridgeABI.abi)

function useBridgeDepositCallback(options: any) {
  const { account, chainId, provider } = useWeb3React()
  const analyticsContext = useTrace()
  const bridgeContract = useBridgeContract()
  console.log('111-111options', options)

  return useCallback(async (): Promise<TransactionResponse> => {
    console.log('aaaoptions', options)
    try {
      if (!account) throw new Error('missing account')
      if (!chainId) throw new Error('missing chainId')
      if (!provider) throw new Error('missing provider')

      //
      // public static swapERC20CallParameters(
      // trades: RouterTrade<Currency, Currency, TradeType>,
      // options: SwapOptions
      // ): MethodParameters {
      // // TODO: use permit if signature included in swapOptions
      // const planner = new RoutePlanner()
      //
      // const trade: UniswapTrade = new UniswapTrade(trades, options)
      //
      // const inputCurrency = trade.trade.inputAmount.currency
      // invariant(!(inputCurrency.isNative && !!options.inputTokenPermit), 'NATIVE_INPUT_PERMIT')
      //
      // if (options.inputTokenPermit) {
      // encodePermit(planner, options.inputTokenPermit)
      // }
      //
      // const nativeCurrencyValue = inputCurrency.isNative
      // ? BigNumber.from(trade.trade.maximumAmountIn(options.slippageTolerance).quotient.toString())
      // : BigNumber.from(0)
      //
      // trade.encode(planner, { allowRevert: false })
      // return SwapRouter.encodePlan(planner, nativeCurrencyValue, {
      // deadline: options.deadlineOrPreviousBlockhash ? BigNumber.from(options.deadlineOrPreviousBlockhash) : undefined,
      // })
      // }
      //
      // // /**
      // //  * Encodes a planned route into a method name and parameters for the Router contract.
      // //  * @param planner the planned route
      // //  * @param nativeCurrencyValue the native currency value of the planned route
      // //  * @param config the router config
      // //
      // private static encodePlan(
      //                   planner: RoutePlanner,
      //                   nativeCurrencyValue: BigNumber,
      //                   config: SwapRouterConfig = {}
      // ): MethodParameters {
      //                   const { commands, inputs } = planner
      //                   const functionSignature = !!config.deadline ? 'execute(bytes,bytes[],uint256)' : 'execute(bytes,bytes[])'
      //                   const parameters = !!config.deadline ? [commands, inputs, config.deadline] : [commands, inputs]
      //                   const calldata = SwapRouter.INTERFACE.encodeFunctionData(functionSignature, parameters)
      //                   return { calldata, value: nativeCurrencyValue.toHexString() }
      // }
      //

      //const functionSignature = 'deposit(uint8,bytes32,bytes)'
      console.log('111-options222', options, options.permit)
      console.log('zxcv1', options)
      const { currencyAmount }: { currencyAmount: CurrencyAmount<Token> } = options
      const functionSignature = 'deposit(uint8,bytes32,bytes)'
      //const functionSignature = 'deposit(uint8,bytes32,bytes)'

      const targetChain: SupportedL1ChainId =
        chainId && L1_CHAIN_IDS.some((id) => id === chainId)
          ? chainPair[chainId as SupportedL1ChainId]
          : SupportedChainId.MAINNET

      const destinatinChainId = BridgeChainIds[targetChain] ?? 0
      // chainId === SupportedChainId.PLAYDAPP_TESTNET || chainId === SupportedChainId.PLAYDAPP_MAINNET ? 0 : 1

      console.log('destinatinChainId', destinatinChainId)

      const resourceId = '0x0000000000000000000000fDca085991eB60a127c442250f15748FAc38079400'
      //const amount1000000 = 1e18
      const amount = currencyAmount.quotient.toString()
      const recipient = account

      // const { signature } = options.permit
      // const { v, r, s, deadline: sigDeadline } = signature
      console.log('123124 sig--', resourceId, amount)
      //
      // //TODO:// depositdata for ERC20 bridging
      // TPDO:// permit 할때 spender 주소도 bridge에서  받아오자  (const handlerAddress = await bridgeInstance.handlerAddressByResourceId(args.resourceId);)
      // const depositData =
      // '0x' +
      // hexZeroPad(BigNumber.from(amount.toString()).toHexString(), 32).substr(2) + // Deposit Amount        (32 bytes)
      // hexZeroPad(hexlify((recipient.length - 2) / 2), 32).substr(2) + // len(recipientAddress) (32 bytes)
      // hexZeroPad(recipient, 32).substr(2) +
      // hexZeroPad(BigNumber.from(sigDeadline).toHexString(), 32).substr(2) + // Deadline
      // hexZeroPad(BigNumber.from(v).toHexString(), 32).substr(2) + // v
      // hexZeroPad(r, 32).substr(2) + // r
      // hexZeroPad(s, 32).substr(2) // s
      //

      // const depositData =
      //   '0x' +
      //   hexZeroPad(resourceId, 32).substr(2) + //resourceId
      //   hexZeroPad(account, 32).substr(2) + //depositor
      //   hexZeroPad(BigNumber.from(amount.toString()).toHexString(), 32).substr(2) + // Deposit Amount        (32 bytes)
      //   hexZeroPad(hexlify((recipient.length - 2) / 2), 32).substr(2) + // len(recipientAddress) (32 bytes)
      //   hexZeroPad(recipient, 32).substr(2) +
      //   hexZeroPad(BigNumber.from(sigDeadline).toHexString(), 32).substr(2) + // Deadline
      //   hexZeroPad(BigNumber.from(v).toHexString(), 32).substr(2) + // v
      //   hexZeroPad(r, 32).substr(2) + // r
      //   hexZeroPad(s, 32).substr(2) // s

      const depositData =
        '0x' +
        hexZeroPad(resourceId, 32).substr(2) + // resourceID       (32 bytes)
        hexZeroPad(account, 32).substr(2) + // depositer (32)
        hexZeroPad(BigNumber.from(amount).toHexString(), 32).substr(2) + // Deposit Amount        (32 bytes)
        hexZeroPad(hexlify((recipient.length - 2) / 2), 32).substr(2) + // len(recipientAddress) (32 bytes)
        hexZeroPad(recipient, 32).substr(2) // recipientAddress      (?? bytes)

      console.log('depositData', depositData)
      const parameters = [destinatinChainId, resourceId, depositData]
      const calldata = BridgeInterface.encodeFunctionData(functionSignature, parameters)
      const value = options.value ?? '0x0'
      const tx = {
        from: account,
        to: bridgeContract?.address,
        data: calldata,
        // TODO(https://github.com/Uniswap/universal-router-sdk/issues/113): universal-router-sdk returns a non-hexlified value.
        ...(value && !isZero(value) ? { value: numberToWei(+options.value) } : {}),
      }

      let gasEstimate: BigNumber
      try {
        gasEstimate = await provider.estimateGas(tx)
      } catch (gasError) {
        // setTraceStatus('failed_precondition')
        // setTraceError(gasError)
        console.warn('gasError', gasError)
        throw new GasEstimationError()
      }
      const gasLimit = calculateGasMargin(gasEstimate)
      // setTraceData('gasLimit', gasLimit.toNumber())
      const response = await provider
        .getSigner()
        .sendTransaction({ ...tx, gasLimit })
        .then((response) => {
          if (tx.data !== response.data) {
            throw new ModifiedSwapError()
          }
          return response
        })
      return response
    } catch (swapError: unknown) {
      console.error('error', swapError)
      if (swapError instanceof ModifiedSwapError) throw swapError

      // GasEstimationErrors are already traced when they are thrown.
      // if (!(swapError instanceof GasEstimationError)) setTraceError(swapError)

      // Cancellations are not failures, and must be accounted for as 'cancelled'.
      if (didUserReject(swapError)) {
        // setTraceStatus('cancelled')
        // This error type allows us to distinguish between user rejections and other errors later too.
        throw new UserRejectedRequestError(swapErrorToUserReadableMessage(swapError))
      }

      throw new Error(swapErrorToUserReadableMessage(swapError))
    }
  }, [account, chainId, provider, options, bridgeContract])
}

export function useDepositCallback(
  permitSignature: PermitSignature | undefined,
  currencyAmount: CurrencyAmount<Currency> | undefined,
  value?: string
): {
  callback: null | (() => Promise<string>)
} {
  console.log('useDepositCallback permitSignature---', currencyAmount?.toExact())
  const deadline = useTransactionDeadline()
  const addTransaction = useTransactionAdder()
  // const universalRouterSwapCallback = useUniversalRouterSwapCallback(trade, fiatValues, {
  //   slippageTolerance: allowedSlippage,
  //   deadline,
  //   permit: permitSignature,
  // })
  // console.log('zxcv', currencyAmount)
  const depositCallback = useBridgeDepositCallback({
    deadline,
    permit: permitSignature,
    currencyAmount,
    value,
  })

  const callback = useMemo(() => {
    if (!depositCallback) return null
    const info: TransactionInfo = {
      type: TransactionType.BRIDGE,
    }
    return () =>
      depositCallback().then((response) => {
        addTransaction(response, info, deadline?.toNumber())
        return response.hash
      })
  }, [addTransaction, deadline, depositCallback])

  return {
    callback,
  }
}
