import { Trans } from '@lingui/macro'
import { Percent } from '@uniswap/sdk-core'
import Column, { AutoColumn } from 'components/Column'
import { InterfaceTrade } from 'state/routing/types'
import { Field } from 'state/swap/actions'
import styled from 'styled-components/macro'
import { Divider } from 'theme'

import { SwapModalHeaderAmount } from './SwapModalHeaderAmount'

const Rule = styled(Divider)`
  margin: 16px 2px 24px 2px;
`

const HeaderContainer = styled(AutoColumn)`
  margin-top: 16px;
`

export default function SwapModalHeader({
  trade,
  allowedSlippage,
  formattedAmounts,
}: {
  trade?: InterfaceTrade
  allowedSlippage: Percent
  formattedAmounts?: { [x: string]: string }
}) {
  // const fiatValueInput = useUSDPrice(trade.inputAmount)
  // const fiatValueOutput = useUSDPrice(trade.outputAmount)

  return (
    <HeaderContainer gap="sm">
      <Column gap="lg">
        <SwapModalHeaderAmount
          field={Field.INPUT}
          label={<Trans>You pay</Trans>}
          amount={+(formattedAmounts?.INPUT || 0)}
        />
        <SwapModalHeaderAmount
          field={Field.OUTPUT}
          label={<Trans>You receive</Trans>}
          amount={+(formattedAmounts?.INPUT || 0)}

          // tooltipText={
          //   trade.tradeType === TradeType.EXACT_INPUT ? (
          //     <ThemedText.Caption>
          //       <Trans>
          //         Output is estimated. You will receive at least{' '}
          //         <b>
          //           {trade.minimumAmountOut(allowedSlippage).toSignificant(6)} {trade.outputAmount.currency.symbol}
          //         </b>{' '}
          //         or the transaction will revert.
          //       </Trans>
          //     </ThemedText.Caption>
          //   ) : (
          //     <ThemedText.Caption>
          //       <Trans>
          //         Input is estimated. You will sell at most{' '}
          //         <b>
          //           {trade.maximumAmountIn(allowedSlippage).toSignificant(6)} {trade.inputAmount.currency.symbol}
          //         </b>{' '}
          //         or the transaction will revert.
          //       </Trans>
          //     </ThemedText.Caption>
          //   )
          // }
        />
      </Column>
      <Rule />
    </HeaderContainer>
  )
}
