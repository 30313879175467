import { Trans } from '@lingui/macro'
import { useWeb3React } from '@web3-react/core'
import { useAccountDrawer } from 'components/AccountDrawer'
import Web3Status from 'components/Web3Status'
import { chainIdToBackendName } from 'graphql/data/util'
import { useIsNftPage } from 'hooks/useIsNftPage'
import { useIsPoolsPage } from 'hooks/useIsPoolsPage'
import { useAtomValue } from 'jotai/utils'
import { Box } from 'nft/components/Box'
import { Row } from 'nft/components/Flex'
import { useProfilePageState } from 'nft/hooks'
import { ProfilePageStateType } from 'nft/types'
import { ReactNode, useCallback } from 'react'
import { X } from 'react-feather'
import { NavLink, NavLinkProps, useLocation, useNavigate } from 'react-router-dom'
import { shouldDisableNFTRoutesAtom } from 'state/application/atoms'
import styled from 'styled-components/macro'
import { BREAKPOINTS } from 'theme'

import darkLogoImgSrc from '../../assets/images/logoDark.png'
import smallLogoImgSrc from '../../assets/images/logoSmall.png'
import lightLogoImgSrc from '../../assets/images/logoWhite.png'
import { useIsNavSearchInputVisible } from '../../nft/hooks/useIsNavSearchInputVisible'
import { useIsDarkMode } from '../../theme/components/ThemeToggle'
import { Bag } from './Bag'
import Blur from './Blur'
import { ChainSelector } from './ChainSelector'
import { SearchBar } from './SearchBar'
import * as styles from './style.css'

const Nav = styled.nav`
  padding: ${({ theme }) => `${theme.navVerticalPad}px 12px`};
  width: 100%;
  height: ${({ theme }) => theme.navHeight}px;
  z-index: 2;
`
const StyledLogo = styled.img`
  width: 218px;
  /*height: 72px;*/
  display: none;

  @media screen and (min-width: ${BREAKPOINTS.lg}px) {
    display: block;
  }
`

const StyledSmallLogo = styled.img`
  width: 36px;
  /*height: 72px;*/
  display: block;

  @media screen and (min-width: ${BREAKPOINTS.lg}px) {
    display: none;
  }
`

const CloseIcon = styled(X)`
  cursor: pointer;
  color: #fff;

  &:hover {
    opacity: 0.8;
  }
`

const StyledButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  outline: none;
  padding: 0;
  background-color: transparent;

  &:hover {
    opacity: 0.8;
  }
`

interface MenuItemProps {
  href: string
  id?: NavLinkProps['id']
  isActive?: boolean
  children: ReactNode
  dataTestId?: string
}

const MenuItem = ({ href, dataTestId, id, isActive, children }: MenuItemProps) => {
  return (
    <NavLink
      to={href}
      className={isActive ? styles.activeMenuItem : styles.menuItem}
      id={id}
      style={{ textDecoration: 'none', width: 'auto' }}
      data-testid={dataTestId}
    >
      {children}
    </NavLink>
  )
}

export const PageTabs = () => {
  const { pathname } = useLocation()
  const { chainId: connectedChainId } = useWeb3React()
  const chainName = chainIdToBackendName(connectedChainId)

  const isPoolActive = useIsPoolsPage()
  const isNftPage = useIsNftPage()

  const shouldDisableNFTRoutes = useAtomValue(shouldDisableNFTRoutesAtom)

  return (
    <>
      <MenuItem href="/bridge/erc20" isActive={pathname.startsWith('/bridge/erc20')}>
        <Trans>ERC20</Trans>
      </MenuItem>
      {/*<MenuItem href={`/tokens/${chainName.toLowerCase()}`} isActive={pathname.startsWith('/tokens')}>*/}
      {/*  <Trans>Tokens</Trans>*/}
      {/*</MenuItem>*/}
      {/*{!shouldDisableNFTRoutes && (*/}
      {/*  <MenuItem dataTestId="nft-nav" href="/nfts" isActive={isNftPage}>*/}
      {/*    <Trans>NFT</Trans>*/}
      {/*  </MenuItem>*/}
      {/*)}*/}
      {/*<Box display={{ sm: 'flex', lg: 'none', xxl: 'flex' }} width="full">*/}
      {/*  <MenuItem href="/pools" dataTestId="pool-nav-link" isActive={isPoolActive}>*/}
      {/*    <Trans>Pools</Trans>*/}
      {/*  </MenuItem>*/}
      {/*</Box>*/}
      {/*<Box marginY={{ sm: '4', md: 'unset' }}>*/}
      {/*  <MenuDropdown />*/}
      {/*</Box>*/}
    </>
  )
}

const Navbar = ({ blur }: { blur: boolean }) => {
  const isNftPage = useIsNftPage()
  const sellPageState = useProfilePageState((state) => state.state)
  const navigate = useNavigate()
  const isNavSearchInputVisible = useIsNavSearchInputVisible()

  const [accountDrawerOpen, toggleAccountDrawer] = useAccountDrawer()

  const handleUniIconClick = useCallback(() => {
    if (accountDrawerOpen) {
      toggleAccountDrawer()
    }
    navigate({
      pathname: '/',
      search: '?intro=true',
    })
  }, [accountDrawerOpen, navigate, toggleAccountDrawer])

  const isDarkMode = useIsDarkMode()
  return (
    <>
      {blur && <Blur />}
      <Nav>
        <Box display="flex" height="full" flexWrap="nowrap">
          <Box className={styles.leftSideContainer}>
            <Box className={styles.logoContainer}>
              {/*<UniIcon*/}
              {/*  width="48"*/}
              {/*  height="48"*/}
              {/*  data-testid="uniswap-logo"*/}
              {/*  className={styles.logo}*/}
              {/*  onClick={handleUniIconClick}*/}
              {/*/>*/}
              <StyledLogo src={isDarkMode ? darkLogoImgSrc : lightLogoImgSrc} alt="PlayDapp Logo" />
              <StyledSmallLogo src={smallLogoImgSrc} alt="PlayDapp Logo" />
            </Box>
            {!isNftPage && (
              <Box display={{ sm: 'flex', lg: 'none' }}>
                <ChainSelector leftAlign={true} />
              </Box>
            )}
            {/* <Row display={{ sm: 'none', lg: 'flex' }} style={{ flex: 1 }}>
              <PageTabs />
            </Row> */}
          </Box>
          {/*<Box*/}
          {/*  className={styles.searchContainer}*/}
          {/*  {...(isNavSearchInputVisible && {*/}
          {/*    display: 'flex',*/}
          {/*  })}*/}
          {/*>*/}
          {/*  <SearchBar />*/}
          {/*</Box>*/}
          <Box className={styles.rightSideContainer}>
            <Row gap="12">
              <Box position="relative" display={isNavSearchInputVisible ? 'none' : { sm: 'flex' }}>
                <SearchBar />
              </Box>
              {isNftPage && sellPageState !== ProfilePageStateType.LISTING && <Bag />}
              {!isNftPage && (
                <Box display={{ sm: 'none', lg: 'flex' }}>
                  <ChainSelector />
                </Box>
              )}

              <Web3Status />
            </Row>
          </Box>
        </Box>
      </Nav>
    </>
  )
}

export default Navbar
