import { ApolloClient, useQuery } from '@apollo/client'
import { Trans } from '@lingui/macro'
import { sendAnalyticsEvent, Trace, TraceEvent, useTrace } from '@uniswap/analytics'
import {
  BrowserEvent,
  InterfaceElementName,
  InterfaceEventName,
  InterfacePageName,
  InterfaceSectionName,
  SharedEventName,
  SwapEventName,
} from '@uniswap/analytics-events'
import { formatCurrencyAmount, NumberType } from '@uniswap/conedison/format'
import { Currency, CurrencyAmount, Percent, Token } from '@uniswap/sdk-core'
import { useWeb3React } from '@web3-react/core'
import { useToggleAccountDrawer } from 'components/AccountDrawer'
import { parseLocalActivity } from 'components/AccountDrawer/MiniPortfolio/Activity/parseLocal'
import { sendEvent } from 'components/analytics'
import { NetworkAlert } from 'components/NetworkAlert/NetworkAlert'
import PriceImpactModal from 'components/swap/PriceImpactModal'
import PriceImpactWarning from 'components/swap/PriceImpactWarning'
import { getChainInfo } from 'constants/chainInfo'
import {
  BridgeChainIds,
  chainPair,
  destinationChainId,
  isSupportedChain,
  L1_CHAIN_IDS,
  SupportedChainId,
  SupportedL1ChainId,
} from 'constants/chains'
import { ethers } from 'ethers'
import { GraphClientByChain } from 'graphql/data/apollo'
import { useDepositCallback } from 'hooks/useDepositCallback'
import useENSAddress from 'hooks/useENSAddress'
import { useMaxAmountIn } from 'hooks/useMaxAmountIn'
import usePermit2Allowance, { Allowance, AllowanceState } from 'hooks/usePermit2Allowance'
import usePrevious from 'hooks/usePrevious'
import { useSwitchChain } from 'hooks/useSwitchChain'
import { useUSDPrice } from 'hooks/useUSDPrice'
import JSBI from 'jsbi'
import { chainIdToNetworkName } from 'lib/hooks/useCurrencyLogoURIs'
import { DEPOSIT_ITEMS, PROPOSAL_EVENTS } from 'query/bridge-status'
import { ReactNode, useCallback, useEffect, useMemo, useReducer, useState } from 'react'
import { ArrowDown } from 'react-feather'
import { useLocation, useNavigate } from 'react-router-dom'
import { Text } from 'rebass'
import { useActivePopups } from 'state/application/hooks'
import { useAppSelector } from 'state/hooks'
import { InterfaceTrade, TradeState } from 'state/routing/types'
import { useTransaction } from 'state/transactions/hooks'
import styled, { useTheme } from 'styled-components/macro'
import { didUserReject } from 'utils/swapErrorToUserReadableMessage'

import { getActivityTitle } from '../../components/AccountDrawer/MiniPortfolio/constants'
import AddressInputPanel from '../../components/AddressInputPanel'
import { ButtonError, ButtonLight, ButtonPrimary } from '../../components/Button'
import { AutoColumn } from '../../components/Column'
import SwapCurrencyInputPanel from '../../components/CurrencyInputPanel/SwapCurrencyInputPanel'
import { AutoRow } from '../../components/Row'
import confirmPriceImpactWithoutFee from '../../components/swap/confirmPriceImpactWithoutFee'
import ConfirmSwapModal from '../../components/swap/ConfirmSwapModal'
import { ArrowWrapper, PageWrapper, SwapWrapper } from '../../components/swap/styleds'
import SwapHeader from '../../components/swap/SwapHeader'
import { SwitchLocaleLink } from '../../components/SwitchLocaleLink'
import { BRIDGE_ADDRESSES, BRIDGE_GENERIC_HANDLER_ADDRESSES, BRIDGE_RELAYER_ADDRESSES } from '../../constants/addresses'
import { RPC_URLS } from '../../constants/networks'
import { getSwapCurrencyId, TOKEN_SHORTHANDS } from '../../constants/tokens'
import { TransactionStatus } from '../../graphql/data/__generated__/types-and-hooks'
import { useAllTokensMultichain, useCurrency, useDefaultActiveTokens } from '../../hooks/Tokens'
import { useBridgeContract, useBridgeGenericHandlerContract } from '../../hooks/useContract'
import { PermitInfo, PermitType } from '../../hooks/useERC20Permit'
import { useIsSwapUnsupported } from '../../hooks/useIsSwapUnsupported'
import useSelectChain from '../../hooks/useSelectChain'
import { useSwapCallback } from '../../hooks/useSwapCallback'
import useTransactionDeadline from '../../hooks/useTransactionDeadline'
import useWrapCallback, { WrapErrorText, WrapType } from '../../hooks/useWrapCallback'
import { useCurrencyBalance } from '../../state/connection/hooks'
import { Field, replaceSwapState } from '../../state/swap/actions'
import { useDefaultsFromURLSearch, useDerivedSwapInfo, useSwapActionHandlers } from '../../state/swap/hooks'
import swapReducer, { initialState as initialSwapState, SwapState } from '../../state/swap/reducer'
import { TransactionType } from '../../state/transactions/types'
import { ExternalLinkIcon, LinkStyledButton, ThemedText } from '../../theme'
import { computeFiatValuePriceImpact } from '../../utils/computeFiatValuePriceImpact'
import { ExplorerDataType, getExplorerLink } from '../../utils/getExplorerLink'
import { maxAmountSpend } from '../../utils/maxAmountSpend'
import { computeRealizedPriceImpact, warningSeverity } from '../../utils/prices'
import { supportedChainId } from '../../utils/supportedChainId'

type BridgeProgressStatus = {
  nonce: number
  steps: { status: number; transactionHash: string }[]
  depositItem: { transactionHash: string }
  proposalItemPending: { transactionHash: string }[]
  proposalItemComplete: { transactionHash: string }[]
}

export const ArrowContainer = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;
`

const SwapSection = styled.div`
  position: relative;
  background-color: ${({ theme }) => theme.backgroundModule};
  border-radius: 12px;
  padding: 16px;
  color: ${({ theme }) => theme.textSecondary};
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;

  &:before {
    box-sizing: border-box;
    background-size: 100%;
    border-radius: inherit;

    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;
    pointer-events: none;
    content: '';
    border: 1px solid ${({ theme }) => theme.backgroundModule};
  }

  &:hover:before {
    border-color: ${({ theme }) => theme.stateOverlayHover};
  }

  &:focus-within:before {
    border-color: ${({ theme }) => theme.stateOverlayPressed};
  }
`

const OutputSwapSection = styled(SwapSection)`
  border-bottom: ${({ theme }) => `1px solid ${theme.backgroundSurface}`};
`

const BridgeProgressBlock = styled.div`
  display: flex;
  padding: 16px;
`

const BridgeStepBlock = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 8px;
`

const BridgeStepperBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const BridgeStepNumberBlock = styled.div<{ isActive?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: none;
  text-align: center;
  width: 24px;
  height: 24px;
  font-family: Inter, sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  border-radius: 100px;
  color: ${({ theme, isActive }) => (isActive ? theme.accentAction : '#565C63')};
  border: ${({ theme, isActive }) => `1px solid ${isActive ? theme.accentAction : '#E0E7ED'}`};
`

const BridgeStepperRuler = styled.div<{ isActive?: boolean }>`
  width: 100%;
  height: 2px;
  background-color: ${({ theme, isActive }) => (isActive ? theme.accentAction : '#E0E7ED')};
`

const BridgeStepTextBlock = styled.div<{ isActive?: boolean }>`
  p,
  a {
    margin: 0;
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    color: ${({ theme, isActive }) => (isActive ? theme.accentActionDark : '#565C63')};
  }

  a:hover {
    text-decoration: underline;
  }
  justify-content: center;
  display: flex;
`

const MainContentWrapper = styled.main`
  background-color: ${({ theme }) => theme.backgroundSurface};
  border: 1px solid ${({ theme }) => theme.backgroundOutline};
  padding: 0;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.01), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04),
    0px 24px 32px rgba(0, 0, 0, 0.01);
  overflow: hidden;
  margin-bottom: 50px;
`

const ErrorContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  max-width: 300px;
  min-height: 80px;
`

function getIsValidSwapQuote(
  trade: InterfaceTrade | undefined,
  tradeState: TradeState,
  swapInputError?: ReactNode
): boolean {
  return Boolean(swapInputError && trade && tradeState === TradeState.VALID)
}

function largerPercentValue(a?: Percent, b?: Percent) {
  if (a && b) {
    return a.greaterThan(b) ? a : b
  } else if (a) {
    return a
  } else if (b) {
    return b
  }
  return undefined
}

const TRADE_STRING = 'SwapRouter'

export default function BridgePage({ className }: { className?: string }) {
  const { chainId: connectedChainId } = useWeb3React()
  const loadedUrlParams = useDefaultsFromURLSearch()

  console.log('loadedUrlParams', loadedUrlParams)

  const location = useLocation()

  return (
    <Trace page={InterfacePageName.SWAP_PAGE} shouldLogImpression>
      <PageWrapper>
        <Bridge
          className={className}
          chainId={connectedChainId}
          prefilledState={{
            [Field.INPUT]: { currencyId: loadedUrlParams?.[Field.INPUT]?.currencyId },
            [Field.OUTPUT]: { currencyId: loadedUrlParams?.[Field.OUTPUT]?.currencyId },
          }}
        />
        <NetworkAlert />
      </PageWrapper>
      {location.pathname === '/swap' && <SwitchLocaleLink />}
    </Trace>
  )
}

/**
 * The swap component displays the swap interface, manages state for the swap, and triggers onchain swaps.
 *
 * In most cases, chainId should refer to the connected chain, i.e. `useWeb3React().chainId`.
 * However if this component is being used in a context that displays information from a different, unconnected
 * chain (e.g. the TDP), then chainId should refer to the unconnected chain.
 */

const initialState = {
  showConfirm: false,
  tradeToConfirm: undefined,
  swapError: undefined,
  txHash: undefined,
}

// eslint-disable-next-line import/no-unused-modules
export function Bridge({
  className,
  prefilledState = {},
  chainId,
  onCurrencyChange,
  disableTokenInputs = false,
}: {
  className?: string
  prefilledState?: Partial<SwapState>
  chainId?: SupportedChainId
  onCurrencyChange?: (selected: Pick<SwapState, Field.INPUT | Field.OUTPUT>) => void
  disableTokenInputs?: boolean
}) {
  const { account, chainId: connectedChainId, connector, provider } = useWeb3React()
  const trace = useTrace()

  // token warning stuff
  const prefilledInputCurrency = useCurrency(prefilledState?.[Field.INPUT]?.currencyId)
  const prefilledOutputCurrency = useCurrency(prefilledState?.[Field.OUTPUT]?.currencyId)

  const [loadedInputCurrency, setLoadedInputCurrency] = useState(prefilledInputCurrency)
  const [loadedOutputCurrency, setLoadedOutputCurrency] = useState(prefilledOutputCurrency)

  useEffect(() => {
    setLoadedInputCurrency(prefilledInputCurrency)
    setLoadedOutputCurrency(prefilledOutputCurrency)
  }, [prefilledInputCurrency, prefilledOutputCurrency])

  const [dismissTokenWarning, setDismissTokenWarning] = useState<boolean>(false)
  const [showPriceImpactModal, setShowPriceImpactModal] = useState<boolean>(false)

  const urlLoadedTokens: Token[] = useMemo(
    () => [loadedInputCurrency, loadedOutputCurrency]?.filter((c): c is Token => c?.isToken ?? false) ?? [],
    [loadedInputCurrency, loadedOutputCurrency]
  )
  const handleConfirmTokenWarning = useCallback(() => {
    setDismissTokenWarning(true)
  }, [])

  // dismiss warning if all imported tokens are in active lists
  const defaultTokens = useDefaultActiveTokens(chainId)

  const importTokensNotInDefault = useMemo(
    () =>
      urlLoadedTokens &&
      urlLoadedTokens
        .filter((token: Token) => {
          return !(token.address in defaultTokens)
        })
        .filter((token: Token) => {
          // Any token addresses that are loaded from the shorthands map do not need to show the import URL
          const supported = supportedChainId(chainId)
          if (!supported) return true
          return !Object.keys(TOKEN_SHORTHANDS).some((shorthand) => {
            const shorthandTokenAddress = TOKEN_SHORTHANDS[shorthand][supported]
            return shorthandTokenAddress && shorthandTokenAddress === token.address
          })
        }),
    [chainId, defaultTokens, urlLoadedTokens]
  )

  const theme = useTheme()

  // toggle wallet when disconnected
  const toggleWalletDrawer = useToggleAccountDrawer()

  // swap state
  const [state, dispatch] = useReducer(swapReducer, { ...initialSwapState, ...prefilledState })
  const { typedValue, recipient, independentField } = state

  const previousConnectedChainId = usePrevious(connectedChainId)
  const previousPrefilledState = usePrevious(prefilledState)
  useEffect(() => {
    const combinedInitialState = { ...initialSwapState, ...prefilledState }
    const chainChanged = previousConnectedChainId && previousConnectedChainId !== connectedChainId
    const prefilledInputChanged =
      previousPrefilledState &&
      previousPrefilledState?.[Field.INPUT]?.currencyId !== prefilledState?.[Field.INPUT]?.currencyId
    const prefilledOutputChanged =
      previousPrefilledState &&
      previousPrefilledState?.[Field.OUTPUT]?.currencyId !== prefilledState?.[Field.OUTPUT]?.currencyId
    if (chainChanged || prefilledInputChanged || prefilledOutputChanged) {
      dispatch(
        replaceSwapState({
          ...initialSwapState,
          ...prefilledState,
          field: combinedInitialState.independentField ?? Field.INPUT,
          inputCurrencyId: combinedInitialState.INPUT.currencyId ?? undefined,
          outputCurrencyId: combinedInitialState.OUTPUT.currencyId ?? undefined,
        })
      )
      // reset local state
      setSwapState({
        tradeToConfirm: undefined,
        swapError: undefined,
        showConfirm: false,
        txHash: undefined,
      })
    }
  }, [connectedChainId, prefilledState, previousConnectedChainId, previousPrefilledState])

  const {
    trade: { state: tradeState, trade, method },
    allowedSlippage,
    autoSlippage,
    currencyBalances,
    parsedAmount,
    currencies,
    inputError: swapInputError,
  } = useDerivedSwapInfo(state, chainId)

  const {
    wrapType,
    execute: onWrap,
    inputError: wrapInputError,
  } = useWrapCallback(currencies[Field.INPUT], currencies[Field.OUTPUT], typedValue)
  const showWrap: boolean = wrapType !== WrapType.NOT_APPLICABLE
  const { address: recipientAddress } = useENSAddress(recipient)

  const parsedAmounts = useMemo(
    () =>
      showWrap
        ? {
            [Field.INPUT]: parsedAmount,
            [Field.OUTPUT]: parsedAmount,
          }
        : {
            [Field.INPUT]: independentField === Field.INPUT ? parsedAmount : trade?.inputAmount,
            [Field.OUTPUT]: independentField === Field.OUTPUT ? parsedAmount : trade?.outputAmount,
          },
    [independentField, parsedAmount, showWrap, trade]
  )

  const fiatValueInput = useUSDPrice(parsedAmounts[Field.INPUT])
  const fiatValueOutput = useUSDPrice(parsedAmounts[Field.OUTPUT])
  const showFiatValueInput = Boolean(parsedAmounts[Field.INPUT])
  const showFiatValueOutput = Boolean(parsedAmounts[Field.OUTPUT])

  const [routeNotFound, routeIsLoading, routeIsSyncing] = useMemo(
    () => [!trade?.swaps, TradeState.LOADING === tradeState, TradeState.LOADING === tradeState && Boolean(trade)],
    [trade, tradeState]
  )

  const fiatValueTradeInput = useUSDPrice(trade?.inputAmount)
  const fiatValueTradeOutput = useUSDPrice(trade?.outputAmount)
  const stablecoinPriceImpact = useMemo(
    () =>
      routeIsSyncing || !trade
        ? undefined
        : computeFiatValuePriceImpact(fiatValueTradeInput.data, fiatValueTradeOutput.data),
    [fiatValueTradeInput, fiatValueTradeOutput, routeIsSyncing, trade]
  )

  const { onSwitchTokens, onCurrencySelection, onUserInput, onChangeRecipient } = useSwapActionHandlers(dispatch)
  const isValid = !swapInputError
  const dependentField: Field = independentField === Field.INPUT ? Field.OUTPUT : Field.INPUT

  const handleTypeInput = useCallback(
    (value: string) => {
      onUserInput(Field.INPUT, value)
    },
    [onUserInput]
  )
  const handleTypeOutput = useCallback(
    (value: string) => {
      onUserInput(Field.OUTPUT, value)
    },
    [onUserInput]
  )

  const navigate = useNavigate()
  const swapIsUnsupported = useIsSwapUnsupported(currencies[Field.INPUT], currencies[Field.OUTPUT])

  // reset if they close warning without tokens in params
  const handleDismissTokenWarning = useCallback(() => {
    setDismissTokenWarning(true)
    navigate('/swap/')
  }, [navigate])

  // modal and loading
  const [{ showConfirm, tradeToConfirm, swapError, txHash }, setSwapState] = useState<{
    showConfirm: boolean
    tradeToConfirm?: InterfaceTrade
    swapError?: Error
    txHash?: string
  }>(initialState)

  const formattedAmounts = useMemo(
    () => ({
      [independentField]: typedValue,
      [dependentField]: showWrap
        ? parsedAmounts[independentField]?.toExact() ?? ''
        : formatCurrencyAmount(parsedAmounts[dependentField], NumberType.SwapTradeAmount, ''),
    }),
    [dependentField, independentField, parsedAmounts, showWrap, typedValue]
  )

  const userHasSpecifiedInputOutput = Boolean(
    currencies[Field.INPUT] && currencies[Field.OUTPUT] && parsedAmounts[independentField]?.greaterThan(JSBI.BigInt(0))
  )
  const bridgeContract = useBridgeContract()
  const bridgeGennericHandlerContract = useBridgeGenericHandlerContract()
  const maximumAmountIn = useMaxAmountIn(trade, allowedSlippage)
  const allowance: Allowance = usePermit2Allowance(
    parsedAmounts[Field.INPUT] as CurrencyAmount<Token>,
    bridgeGennericHandlerContract?.address
  )
  console.log('allowance1234', allowance)
  console.log('parsedAmounts', parsedAmounts, parsedAmounts[Field.INPUT])
  const maxInputAmount: CurrencyAmount<Currency> | undefined = useMemo(
    () => maxAmountSpend(currencyBalances[Field.INPUT]),
    [currencyBalances]
  )
  const showMaxButton = Boolean(maxInputAmount?.greaterThan(0) && !parsedAmounts[Field.INPUT]?.equalTo(maxInputAmount))
  const swapFiatValues = useMemo(() => {
    return { amountIn: fiatValueTradeInput.data, amountOut: fiatValueTradeOutput.data }
  }, [fiatValueTradeInput, fiatValueTradeOutput])

  // the callback to execute the swap
  const { callback: swapCallback } = useSwapCallback(
    trade,
    swapFiatValues,
    allowedSlippage,
    allowance.state === AllowanceState.ALLOWED ? allowance.permitSignature : undefined
  )

  const isPlayDappNetwork = useMemo(() => [12781].some((id) => id === chainId), [chainId])

  console.log('currencies', currencies[Field.INPUT], currencies[Field.OUTPUT])
  const { callback: depositCallback } = useDepositCallback(
    allowance.state === AllowanceState.ALLOWED ? allowance.permitSignature : undefined,
    parsedAmounts[Field.INPUT],
    isPlayDappNetwork ? parsedAmounts[Field.INPUT]?.toExact() || '0' : undefined
  )
  const transactionDeadline = useTransactionDeadline()
  const PERMIT_INFO: PermitInfo = {
    version: '1',
    name: 'MMM COIN',
    type: PermitType.AMOUNT,
  }

  console.log('bridgeContract', bridgeContract)
  // const { gatherPermitSignature, signatureData } = useERC20Permit(
  //   CurrencyAmount.fromRawAmount(PDA_PDTESTNET, 1e18),
  //   bridgeContract?.address,
  //   transactionDeadline,
  //   PERMIT_INFO
  // )

  const handleContinueToReview = useCallback(() => {
    console.log('handleContinueToReview')
    setSwapState({
      tradeToConfirm: trade,
      swapError: undefined,
      showConfirm: true,
      txHash: undefined,
    })
  }, [trade])

  const handleSwap = useCallback(() => {
    console.log('handleSwap')
    if (!swapCallback) {
      return
    }
    if (stablecoinPriceImpact && !confirmPriceImpactWithoutFee(stablecoinPriceImpact)) {
      return
    }
    setSwapState((currentState) => ({
      ...currentState,
      swapError: undefined,
      txHash: undefined,
    }))
    swapCallback()
      .then((hash) => {
        setSwapState((currentState) => ({
          ...currentState,
          swapError: undefined,
          txHash: hash,
        }))
        sendEvent({
          category: 'Swap',
          action: 'transaction hash',
          label: hash,
        })
        sendEvent({
          category: 'Swap',
          action:
            recipient === null
              ? 'Swap w/o Send'
              : (recipientAddress ?? recipient) === account
              ? 'Swap w/o Send + recipient'
              : 'Swap w/ Send',
          label: [TRADE_STRING, trade?.inputAmount?.currency?.symbol, trade?.outputAmount?.currency?.symbol, 'MH'].join(
            '/'
          ),
        })
      })
      .catch((error) => {
        if (!didUserReject(error)) {
          sendAnalyticsEvent(SwapEventName.SWAP_ERROR, {
            confirmedTrade: tradeToConfirm,
          })
        }
        setSwapState((currentState) => ({
          ...currentState,
          swapError: error,
          txHash: undefined,
        }))
      })
  }, [
    swapCallback,
    stablecoinPriceImpact,
    recipient,
    recipientAddress,
    account,
    trade?.inputAmount?.currency?.symbol,
    trade?.outputAmount?.currency?.symbol,
    tradeToConfirm,
  ])

  const [hadleDepositRequested, setHandleDepositRequested] = useState(false)
  const handleDeposit = () => {
    console.log('handleDeposit')
    if (!depositCallback) {
      return
    }
    setHandleDepositRequested(true)
  }
  useEffect(() => {
    if (hadleDepositRequested) {
      performDeposit()
      setHandleDepositRequested(false)
    }
  }, [hadleDepositRequested])

  const performDeposit = useCallback(async () => {
    setSwapState((currentState) => ({
      ...currentState,
      swapError: undefined,
      txHash: undefined,
    }))
    if (!depositCallback) {
      return
    }
    depositCallback()
      .then((hash) => {
        setSwapState((currentState) => ({
          ...currentState,
          swapError: undefined,
          txHash: hash,
        }))
        // allowance.resetSignature && allowance.resetSignature()
      })
      .catch((error) => {
        if (!didUserReject(error)) {
          sendAnalyticsEvent(SwapEventName.SWAP_ERROR, {
            confirmedTrade: tradeToConfirm,
          })
        }
        setSwapState((currentState) => ({
          ...currentState,
          swapError: error,
          txHash: undefined,
        }))
        // allowance.resetSignature && allowance.resetSignature()
      })
  }, [depositCallback, account])

  // errors
  const [swapQuoteReceivedDate, setSwapQuoteReceivedDate] = useState<Date | undefined>()

  // warnings on the greater of fiat value price impact and execution price impact
  const { priceImpactSeverity, largerPriceImpact } = useMemo(() => {
    const marketPriceImpact = trade?.priceImpact ? computeRealizedPriceImpact(trade) : undefined
    const largerPriceImpact = largerPercentValue(marketPriceImpact, stablecoinPriceImpact)
    return { priceImpactSeverity: warningSeverity(largerPriceImpact), largerPriceImpact }
  }, [stablecoinPriceImpact, trade])

  const handleConfirmDismiss = useCallback(() => {
    setSwapState((currentState) => ({ ...currentState, showConfirm: false }))
    // if there was a tx hash, we want to clear the input
    if (txHash) {
      onUserInput(Field.INPUT, '')
    }
  }, [onUserInput, txHash])

  const handleAcceptChanges = useCallback(() => {
    setSwapState((currentState) => ({ ...currentState, tradeToConfirm: trade }))
  }, [trade])

  const handleInputSelect = useCallback(
    (inputCurrency: Currency) => {
      onCurrencySelection(Field.INPUT, inputCurrency)
      onCurrencyChange?.({
        [Field.INPUT]: {
          currencyId: getSwapCurrencyId(inputCurrency),
        },
        [Field.OUTPUT]: state[Field.OUTPUT],
      })
    },
    [onCurrencyChange, onCurrencySelection, state]
  )

  const handleMaxInput = useCallback(() => {
    maxInputAmount && onUserInput(Field.INPUT, maxInputAmount.toExact())
    sendEvent({
      category: 'Swap',
      action: 'Max',
    })
  }, [maxInputAmount, onUserInput])

  const handleOutputSelect = useCallback(
    (outputCurrency: Currency) => {
      console.log('selected mbp network token', outputCurrency)
      // TODO: 코인 선택했을 때 동작 추가
      onCurrencySelection(Field.OUTPUT, outputCurrency)
      onCurrencyChange?.({
        [Field.INPUT]: state[Field.INPUT],
        [Field.OUTPUT]: {
          currencyId: getSwapCurrencyId(outputCurrency),
        },
      })
    },
    [onCurrencyChange, onCurrencySelection, state]
  )

  const showPriceImpactWarning = largerPriceImpact && priceImpactSeverity > 3

  const prevTrade = usePrevious(trade)
  useEffect(() => {
    if (!trade || prevTrade === trade) return // no new swap quote to log

    setSwapQuoteReceivedDate(new Date())
  }, [prevTrade, trade, trace, allowedSlippage, method])

  const showDetailsDropdown = Boolean(
    !showWrap && userHasSpecifiedInputOutput && (trade || routeIsLoading || routeIsSyncing)
  )

  const switchChain = useSwitchChain()
  const switchingChain = useAppSelector((state) => state.wallets.switchingChain)

  const targetChain: SupportedL1ChainId =
    connectedChainId && L1_CHAIN_IDS.some((id) => id === connectedChainId)
      ? chainPair[connectedChainId as SupportedL1ChainId]
      : SupportedChainId.MAINNET

  const destinatinChainId = BridgeChainIds[targetChain] ?? 0

  const [sourceChainGraphClient, setSourceChainGraphClient] = useState<ApolloClient<any> | undefined>(undefined)
  const [destChainGraphClient, setDestChainGraphClient] = useState<ApolloClient<any> | undefined>(undefined)

  useEffect(() => {
    if (connectedChainId) {
      setSourceChainGraphClient(GraphClientByChain[connectedChainId as SupportedL1ChainId])
      setDestChainGraphClient(
        GraphClientByChain[chainPair[connectedChainId as SupportedL1ChainId] as SupportedL1ChainId]
      )
    }
  }, [connectedChainId])

  /* BRIDGE STATUS */
  const { data: depositItemsData, startPolling: startDepositPolling } = useQuery(DEPOSIT_ITEMS, {
    variables: { id: account },
    client: sourceChainGraphClient,
    skip: !sourceChainGraphClient,
  })

  const { data: proposalEventsData, startPolling: startProposalPolling } = useQuery(PROPOSAL_EVENTS, {
    variables: { id: account },
    client: destChainGraphClient,
    skip: !destChainGraphClient,
  })

  const activePopups = useActivePopups()
  const activeTxHash = useMemo(
    () => (activePopups.length > 0 && 'txn' in activePopups[0].content ? activePopups[0].content.txn.hash : undefined),
    [activePopups]
  )
  const tx = useTransaction(activeTxHash)
  const success = tx?.receipt?.status === 1
  const tokens = useAllTokensMultichain()
  const activity = tx && chainId ? parseLocalActivity(tx, chainId, tokens) : null

  const [bridgeProgressStep, setBridgeProgressStep] = useState<BridgeProgressStatus[] | null>(null)
  const [isShowBridgeProgress, setIsShowBridgeProgress] = useState(false)

  useEffect(() => {
    if (
      depositItemsData &&
      Array.isArray(depositItemsData?.depositItems) &&
      depositItemsData?.depositItems.length > 0 &&
      activity &&
      activity.title === getActivityTitle(TransactionType.BRIDGE, TransactionStatus.Confirmed) &&
      success &&
      account
    ) {
      localStorage.setItem(`MPB_BRIDGE_LATELY_NONCE_${account}`, depositItemsData.depositItems[0].depositNonce)
      setIsShowBridgeProgress(true)
    }
  }, [depositItemsData, activity, success, account])

  useEffect(() => {
    if (
      depositItemsData &&
      Array.isArray(depositItemsData?.depositItems) &&
      depositItemsData?.depositItems.length > 0 &&
      proposalEventsData &&
      Array.isArray(proposalEventsData?.proposalEvents) &&
      proposalEventsData?.proposalEvents.length > 0
    ) {
      const steps = depositItemsData?.depositItems.reduce(
        (acc: BridgeProgressStatus[], cur: { depositNonce: number }) => {
          const nonce = cur.depositNonce as number
          const filteredProposalEvents = proposalEventsData?.proposalEvents.filter(
            (value: { depositNonce: number }) => value.depositNonce === nonce
          )
          return [
            ...acc,
            {
              nonce,
              steps: filteredProposalEvents,
              depositItem: cur,
              proposalItemPending: filteredProposalEvents.filter((e: { status: number }) => e.status === 1),
              proposalItemComplete: filteredProposalEvents.filter((e: { status: number }) => e.status === 3),
            },
          ]
        },
        []
      )

      setBridgeProgressStep(steps)
    }
  }, [depositItemsData, proposalEventsData])

  // console.log('bridgeProgressStep', bridgeProgressStep)

  useEffect(() => {
    if (
      bridgeProgressStep &&
      bridgeProgressStep.length > 1 &&
      bridgeProgressStep[0].steps.length === 3 &&
      account &&
      bridgeProgressStep[0].nonce.toString() === localStorage.getItem(`MPB_BRIDGE_LATELY_NONCE_${account}`)
    ) {
      localStorage.removeItem(`MPB_BRIDGE_LATELY_NONCE_${account}`)
    }
  }, [account, bridgeProgressStep])

  useEffect(() => {
    if (account && localStorage.getItem(`MPB_BRIDGE_LATELY_NONCE_${account}`)) {
      setIsShowBridgeProgress(true)
    }
  }, [account])

  useEffect(() => {
    startDepositPolling(3000)
    startProposalPolling(3000)
  }, [startDepositPolling, startProposalPolling, sourceChainGraphClient, destChainGraphClient])

  const useDestinationChainBridgeEthBalance = () => {
    const [balance, setBalance] = useState<ethers.BigNumber>(ethers.BigNumber.from(0))
    const [handlerBalance, setHandlerBalance] = useState<ethers.BigNumber>(ethers.BigNumber.from(0))
    const [error, setError] = useState<string | null>(null)

    useEffect(() => {
      const fetchBalance = async () => {
        try {
          const chainId = BridgeChainIds[connectedChainId as SupportedL1ChainId]

          if (chainId === 0) {
            const provider = new ethers.providers.JsonRpcProvider(
              RPC_URLS[destinationChainId[connectedChainId as SupportedL1ChainId]][0]
            )
            const balance: ethers.BigNumber = await provider.getBalance(
              BRIDGE_ADDRESSES[destinationChainId[connectedChainId as SupportedL1ChainId]]
            )

            setBalance(balance)
          } else if (chainId === 1) {
            const pdaToken = tokens[chainPair[connectedChainId as SupportedL1ChainId]]

            const provider = new ethers.providers.JsonRpcProvider(
              RPC_URLS[chainPair[connectedChainId as SupportedL1ChainId]][0]
            )
            const erc20Contract = new ethers.Contract(
              Object.keys(pdaToken as any)[0] ?? '',
              ['function balanceOf(address) view returns (uint256)'],
              provider
            )
            const balanceErc20 =
              (await erc20Contract?.balanceOf(
                BRIDGE_GENERIC_HANDLER_ADDRESSES[chainPair[connectedChainId as SupportedL1ChainId]]
              )) ?? ethers.BigNumber.from(0)
            setHandlerBalance(balanceErc20)
          }
        } catch (err) {
          setError(err.message)
        }
      }

      // Fetch the balance when the component mounts
      if (connectedChainId) {
        fetchBalance()
      }
    }, [connectedChainId, bridgeProgressStep])

    return { balance, handlerBalance, error }
  }

  const {
    balance: destinationChainBridgeEthBalance,
    handlerBalance: destinationErc20PdaBalance,
    error: destinationChainBridgeEthBalanceError,
  } = useDestinationChainBridgeEthBalance()
  console.log(
    'balance_check',
    'destinationChainBridgeEthBalance',
    destinationChainBridgeEthBalance?.toString(),
    'destinationErc20PdaBalance',
    destinationErc20PdaBalance?.toString(),
    'destinationChainBridgeEthBalanceError',
    !!destinationChainBridgeEthBalanceError
  )
  const useBridgeChainRelayerEthBalance = () => {
    const [ethBalanceSource, setEthBalanceSource] = useState<ethers.BigNumber | null>(null)
    const [ethBalanceDest, setEthBalanceDest] = useState<ethers.BigNumber | null>(null)
    const [destinationChainAccountBalance, setDestinationChainAccountBalance] = useState<ethers.BigNumber>(
      ethers.BigNumber.from(0)
    )
    const [error, setError] = useState<string | null>(null)
    const [loaded, setLoaded] = useState(false)
    useEffect(() => {
      const fetchBalance = async () => {
        try {
          const providerSource = new ethers.providers.JsonRpcProvider(
            RPC_URLS[connectedChainId as SupportedL1ChainId][0]
          )
          const providerDest = new ethers.providers.JsonRpcProvider(
            RPC_URLS[chainPair[connectedChainId as SupportedL1ChainId]][0]
          )
          const balanceSourceRelayer: ethers.BigNumber = await providerSource.getBalance(
            BRIDGE_RELAYER_ADDRESSES[connectedChainId as SupportedL1ChainId]
          )
          const balanceDestRelayer: ethers.BigNumber = await providerDest.getBalance(
            BRIDGE_RELAYER_ADDRESSES[chainPair[connectedChainId as SupportedL1ChainId]]
          )

          if (account) {
            if (destinatinChainId === 0) {
              const pdaToken = tokens[chainPair[connectedChainId as SupportedL1ChainId]]
              const erc20Contract = new ethers.Contract(
                Object.keys(pdaToken as any)[0] ?? '',
                ['function balanceOf(address) view returns (uint256)'],
                providerDest
              )
              const balance = (await erc20Contract?.balanceOf(account)) ?? ethers.BigNumber.from(0)
              setDestinationChainAccountBalance(balance)
            } else if (destinatinChainId === 1) {
              const destinationChainAccountNativeBalance: ethers.BigNumber = await providerDest.getBalance(account)
              setDestinationChainAccountBalance(destinationChainAccountNativeBalance ?? ethers.BigNumber.from(0))
            }
          }

          setEthBalanceSource(balanceSourceRelayer)
          setEthBalanceDest(balanceDestRelayer)
          setLoaded(
            !!balanceSourceRelayer && !!balanceDestRelayer && balanceSourceRelayer.gt(0) && balanceDestRelayer.gt(0)
          )
        } catch (err) {
          setError(err.message)
          setLoaded(true)
        }
      }

      // Fetch the balance when the component mounts
      if (connectedChainId) {
        fetchBalance()
      }
    }, [connectedChainId, account, bridgeProgressStep])

    useEffect(() => {
      setDestinationChainAccountBalance(ethers.BigNumber.from(0))
    }, [connectedChainId])

    return { ethBalanceSource, ethBalanceDest, destinationChainAccountBalance, error, loaded }
  }

  const {
    ethBalanceSource: relayerBalanceSource,
    ethBalanceDest: relayerBalanceDest,
    error: relayerBalanceError,
    loaded: relayerBalanceLoaded,
    destinationChainAccountBalance,
  } = useBridgeChainRelayerEthBalance()

  const selectedCurrencyBalance = useCurrencyBalance(account ?? undefined, currencies[Field.INPUT] ?? undefined)
  const [nativeBalance, setNativeBalance] = useState<ethers.BigNumber>(ethers.BigNumber.from(0))
  useEffect(() => {
    ;(async () => {
      if (provider && account) {
        const balance = await provider.getBalance(account)
        setNativeBalance(balance)
      }
    })()
  }, [provider, account, depositItemsData, activity, isShowBridgeProgress])

  const [depositableAmount, setDepositableAmount] = useState<ethers.BigNumber>(ethers.BigNumber.from(0))
  useEffect(() => {
    const bridgeChainId = BridgeChainIds[connectedChainId as SupportedL1ChainId]
    if (bridgeChainId === 0) {
      setDepositableAmount(ethers.BigNumber.from(ethers.utils.parseEther(selectedCurrencyBalance?.toExact() ?? '0')))
    } else if (bridgeChainId === 1) {
      setDepositableAmount(nativeBalance ?? ethers.BigNumber.from(0))
    }
  }, [connectedChainId, destinatinChainId, selectedCurrencyBalance, nativeBalance])

  //relayer에 최소 0.1이더씩은 있어야 브릿지 가능하고 그 이하면 알림 표시 및 UI에서 입금 불가 처리
  const isBridgeOpen =
    relayerBalanceSource?.gt(ethers.utils.parseEther('0.1')) && relayerBalanceDest?.gt(ethers.utils.parseEther('0.1'))

  const [hasSufficientBridgePool, setHasSufficientBridgePool] = useState(false) //입금입력금액이 브릿지 풀 이내 금액인지
  const [hasSufficientBalance, setHasSufficientBalance] = useState(true) //입금입력금액이 보유한 잔고 이내인지
  useEffect(() => {
    if (connectedChainId && isBridgeOpen && (destinationChainBridgeEthBalance || destinationErc20PdaBalance)) {
      const inputVal = parsedAmounts[Field.INPUT]

      // 플댑네트워크 (BridgeChainId ==1)에서 입금은 이더쪽의 genericHandler의 PDA잔고 내에서,
      // 이더쪽에서 입금은 플댑네트워크 브릿지의 nativePDA 잔고 내에서만 가능

      const isDeposiable =
        ethers.utils.parseEther(inputVal?.toExact() ?? '0').gt(0) &&
        ((BridgeChainIds[connectedChainId as SupportedL1ChainId] === 0 &&
          destinationChainBridgeEthBalance.gte(ethers.utils.parseEther(inputVal?.toExact() ?? '0'))) ||
          (BridgeChainIds[connectedChainId as SupportedL1ChainId] === 1 &&
            destinationErc20PdaBalance.gte(ethers.utils.parseEther(inputVal?.toExact() ?? '0'))))

      setHasSufficientBridgePool(isDeposiable)
      setHasSufficientBalance(ethers.utils.parseEther(inputVal?.toExact() ?? '0').lte(depositableAmount))
    }
  }, [
    connectedChainId,
    parsedAmounts[Field.INPUT],
    isBridgeOpen,
    destinationChainBridgeEthBalance,
    destinationErc20PdaBalance,
    depositableAmount,
  ])

  const selectChain = useSelectChain()
  const requestSwitchChain = useCallback(
    async (targetChainId: SupportedChainId) => {
      await selectChain(targetChainId)
    },
    [selectChain]
  )

  useEffect(() => {
    setIsShowBridgeProgress(false)
  }, [chainId])

  return (
    <>
      {connectedChainId && account && relayerBalanceLoaded && !isBridgeOpen && (
        <MainContentWrapper>
          <ErrorContainer>
            <ThemedText.DeprecatedBody textAlign="center">
              <div data-testid="pools-unsupported-err" style={{ color: 'orange' }}>
                <Trans>
                  Playdapp Bridge is under maintenance at the moment. <br />
                  You may try again later.
                </Trans>
              </div>
            </ThemedText.DeprecatedBody>
          </ErrorContainer>
        </MainContentWrapper>
      )}

      <SwapWrapper chainId={chainId} className={className} id="swap-page">
        {/* <TokenSafetyModal
        isOpen={importTokensNotInDefault.length > 0 && !dismissTokenWarning}
        tokenAddress={importTokensNotInDefault[0]?.address}
        secondTokenAddress={importTokensNotInDefault[1]?.address}
        onContinue={handleConfirmTokenWarning}
        onCancel={handleDismissTokenWarning}
        showCancel={true}
      /> */}
        <SwapHeader autoSlippage={autoSlippage} chainId={chainId} />
        {showConfirm && (
          <ConfirmSwapModal
            trade={trade}
            originalTrade={tradeToConfirm}
            onAcceptChanges={handleAcceptChanges}
            txHash={txHash}
            allowedSlippage={allowedSlippage}
            //onConfirm={handleSwap}
            onConfirm={handleDeposit}
            allowance={allowance}
            swapError={swapError}
            onDismiss={handleConfirmDismiss}
            swapQuoteReceivedDate={swapQuoteReceivedDate}
            fiatValueInput={fiatValueTradeInput}
            fiatValueOutput={fiatValueTradeOutput}
            formattedAmounts={formattedAmounts}
          />
        )}
        {showPriceImpactModal && showPriceImpactWarning && (
          <PriceImpactModal
            priceImpact={largerPriceImpact}
            onDismiss={() => setShowPriceImpactModal(false)}
            onContinue={() => {
              setShowPriceImpactModal(false)
              handleContinueToReview()
            }}
          />
        )}
        <div style={{ display: 'relative' }}>
          <SwapSection>
            <Trace section={InterfaceSectionName.CURRENCY_INPUT_PANEL}>
              <div>From {chainId ? chainIdToNetworkName(chainId) : ''}</div>
              <SwapCurrencyInputPanel
                error={
                  ethers.utils.parseEther(parsedAmounts[Field.INPUT]?.toExact() ?? '0').gt(0) &&
                  !(hasSufficientBridgePool && hasSufficientBalance)
                }
                label={
                  independentField === Field.OUTPUT && !showWrap ? <Trans>From (at most)</Trans> : <Trans>From</Trans>
                }
                disabled={disableTokenInputs}
                value={formattedAmounts[Field.INPUT]}
                showMaxButton={showMaxButton}
                currency={currencies[Field.INPUT] ?? null}
                onUserInput={handleTypeInput}
                onMax={handleMaxInput}
                fiatValue={showFiatValueInput ? fiatValueInput : undefined}
                // onCurrencySelect={handleInputSelect}
                // otherCurrency={currencies[Field.OUTPUT]}
                showCommonBases
                id={InterfaceSectionName.CURRENCY_INPUT_PANEL}
                loading={independentField === Field.OUTPUT && routeIsSyncing}
                balance={depositableAmount}
              />
            </Trace>
          </SwapSection>
          <ArrowWrapper clickable={isSupportedChain(chainId)}>
            <TraceEvent
              events={[BrowserEvent.onClick]}
              name={SwapEventName.SWAP_TOKENS_REVERSED}
              element={InterfaceElementName.SWAP_TOKENS_REVERSE_ARROW_BUTTON}
            >
              <ArrowContainer
                data-testid="swap-currency-button"
                onClick={async () => {
                  !disableTokenInputs && onSwitchTokens()
                  if (connectedChainId) {
                    await requestSwitchChain(chainPair[connectedChainId as SupportedL1ChainId])
                  }
                }}
                color={theme.textPrimary}
              >
                <ArrowDown
                  size="16"
                  color={currencies[Field.INPUT] && currencies[Field.OUTPUT] ? theme.textPrimary : theme.textTertiary}
                />
              </ArrowContainer>
            </TraceEvent>
          </ArrowWrapper>
        </div>
        <AutoColumn gap="xs">
          <div>
            <OutputSwapSection>
              <Trace section={InterfaceSectionName.CURRENCY_OUTPUT_PANEL}>
                <div>
                  To{' '}
                  {chainId && L1_CHAIN_IDS.some((id) => id === chainId)
                    ? chainIdToNetworkName(chainPair[chainId as SupportedL1ChainId])
                    : ''}
                </div>
                <SwapCurrencyInputPanel
                  error={
                    ethers.utils.parseEther(parsedAmounts[Field.INPUT]?.toExact() ?? '0').gt(0) &&
                    !(hasSufficientBridgePool && hasSufficientBalance)
                  }
                  value={formattedAmounts[Field.INPUT]}
                  disabled={disableTokenInputs}
                  onUserInput={handleTypeOutput}
                  label={
                    independentField === Field.INPUT && !showWrap ? <Trans>To (at least)</Trans> : <Trans>To</Trans>
                  }
                  showMaxButton={false}
                  hideBalance={false}
                  fiatValue={showFiatValueOutput ? fiatValueOutput : undefined}
                  priceImpact={stablecoinPriceImpact}
                  currency={currencies[Field.OUTPUT] ?? null}
                  onCurrencySelect={!isPlayDappNetwork ? handleOutputSelect : undefined}
                  otherCurrency={currencies[Field.OUTPUT]}
                  showCommonBases
                  id={InterfaceSectionName.CURRENCY_OUTPUT_PANEL}
                  loading={independentField === Field.OUTPUT && routeIsSyncing}
                  balance={destinationChainAccountBalance}
                />
              </Trace>
              {recipient !== null && !showWrap ? (
                <>
                  <AutoRow justify="space-between" style={{ padding: '0 1rem' }}>
                    <ArrowWrapper clickable={false}>
                      <ArrowDown size="16" color={theme.textSecondary} />
                    </ArrowWrapper>
                    <LinkStyledButton id="remove-recipient-button" onClick={() => onChangeRecipient(null)}>
                      <Trans>- Remove recipient</Trans>
                    </LinkStyledButton>
                  </AutoRow>
                  <AddressInputPanel id="recipient" value={recipient} onChange={onChangeRecipient} />
                </>
              ) : null}
            </OutputSwapSection>
          </div>
          {/* {showDetailsDropdown && (
          <SwapDetailsDropdown
            trade={trade}
            syncing={routeIsSyncing}
            loading={routeIsLoading}
            allowedSlippage={allowedSlippage}
          />
        )} */}

          {isShowBridgeProgress && bridgeProgressStep && bridgeProgressStep.length > 0 && (
            <AutoColumn>
              <BridgeProgressBlock>
                <BridgeStepBlock>
                  <BridgeStepperBlock>
                    <BridgeStepNumberBlock isActive={bridgeProgressStep[0].steps.length >= 0}>
                      <span>1</span>
                    </BridgeStepNumberBlock>
                    {/*<BridgeStepperRuler isActive={bridgeProgressStep[0].steps.length >= 0} />*/}
                  </BridgeStepperBlock>
                  <BridgeStepTextBlock isActive={bridgeProgressStep[0].steps.length >= 0}>
                    <>
                      <a
                        href={`${getExplorerLink(
                          connectedChainId as number,
                          bridgeProgressStep[0].depositItem?.transactionHash,
                          ExplorerDataType.TRANSACTION
                        )}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Deposit
                      </a>
                      <ExternalLinkIcon
                        href={`${getExplorerLink(
                          connectedChainId as number,
                          bridgeProgressStep[0].depositItem?.transactionHash,
                          ExplorerDataType.TRANSACTION
                        )}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      />
                    </>
                  </BridgeStepTextBlock>
                </BridgeStepBlock>

                <BridgeStepBlock>
                  <BridgeStepperBlock>
                    <BridgeStepNumberBlock isActive={bridgeProgressStep[0].steps.length >= 2}>
                      <span>2</span>
                    </BridgeStepNumberBlock>
                    {/*<BridgeStepperRuler isActive={bridgeProgressStep[0].steps.length >= 2} />*/}
                  </BridgeStepperBlock>
                  <BridgeStepTextBlock isActive={bridgeProgressStep[0].steps.length >= 2}>
                    {bridgeProgressStep[0].steps.length > 0 && (
                      <>
                        <a
                          href={`${getExplorerLink(
                            targetChain as number,
                            bridgeProgressStep[0].proposalItemPending?.[0]?.transactionHash,
                            ExplorerDataType.TRANSACTION
                          )}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Processing
                        </a>
                        <ExternalLinkIcon
                          href={`${getExplorerLink(
                            targetChain as number,
                            bridgeProgressStep[0].proposalItemPending?.[0]?.transactionHash,
                            ExplorerDataType.TRANSACTION
                          )}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        />
                      </>
                    )}
                  </BridgeStepTextBlock>
                </BridgeStepBlock>

                <BridgeStepBlock>
                  <BridgeStepperBlock>
                    <BridgeStepNumberBlock isActive={bridgeProgressStep[0].steps.length === 3}>
                      <span>3</span>
                    </BridgeStepNumberBlock>
                    {/*<BridgeStepperRuler isActive={bridgeProgressStep[0].steps.length === 3} />*/}
                  </BridgeStepperBlock>
                  <BridgeStepTextBlock isActive={bridgeProgressStep[0].steps.length === 3}>
                    {bridgeProgressStep[0].steps.length > 2 && (
                      <>
                        <a
                          href={`${getExplorerLink(
                            targetChain as number,
                            bridgeProgressStep[0].proposalItemComplete?.[0]?.transactionHash,
                            ExplorerDataType.TRANSACTION
                          )}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Complete
                        </a>
                        <ExternalLinkIcon
                          href={`${getExplorerLink(
                            targetChain as number,
                            bridgeProgressStep[0].proposalItemComplete?.[0]?.transactionHash,
                            ExplorerDataType.TRANSACTION
                          )}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        />
                      </>
                    )}
                  </BridgeStepTextBlock>
                </BridgeStepBlock>
              </BridgeProgressBlock>
            </AutoColumn>
          )}

          {showPriceImpactWarning && <PriceImpactWarning priceImpact={largerPriceImpact} />}
          <div>
            {swapIsUnsupported ? (
              <ButtonPrimary disabled={true}>
                <ThemedText.DeprecatedMain mb="4px">
                  <Trans>Unsupported Asset</Trans>
                </ThemedText.DeprecatedMain>
              </ButtonPrimary>
            ) : switchingChain ? (
              <ButtonPrimary disabled={true}>
                <Trans>Connecting to {getChainInfo(switchingChain)?.label}</Trans>
              </ButtonPrimary>
            ) : !account ? (
              <TraceEvent
                events={[BrowserEvent.onClick]}
                name={InterfaceEventName.CONNECT_WALLET_BUTTON_CLICKED}
                properties={{ received_swap_quote: getIsValidSwapQuote(trade, tradeState, swapInputError) }}
                element={InterfaceElementName.CONNECT_WALLET_BUTTON}
              >
                <ButtonLight onClick={toggleWalletDrawer} fontWeight={600}>
                  <Trans>Connect Wallet</Trans>
                </ButtonLight>
              </TraceEvent>
            ) : chainId && chainId !== connectedChainId ? (
              <ButtonPrimary
                onClick={async () => {
                  try {
                    await switchChain(connector, chainId)
                  } catch (error) {
                    if (didUserReject(error)) {
                      // Ignore error, which keeps the user on the previous chain.
                    } else {
                      // TODO(WEB-3306): This UX could be improved to show an error state.
                      throw error
                    }
                  }
                }}
              >
                Connect to {getChainInfo(chainId)?.label}
              </ButtonPrimary>
            ) : showWrap ? (
              <ButtonPrimary
                disabled={Boolean(wrapInputError)}
                onClick={onWrap}
                fontWeight={600}
                data-testid="wrap-button"
              >
                {wrapInputError ? (
                  <WrapErrorText wrapInputError={wrapInputError} />
                ) : wrapType === WrapType.WRAP ? (
                  <Trans>Wrap</Trans>
                ) : wrapType === WrapType.UNWRAP ? (
                  <Trans>Unwrap</Trans>
                ) : null}
              </ButtonPrimary>
            ) : (
              <TraceEvent
                events={[BrowserEvent.onClick]}
                name={SharedEventName.ELEMENT_CLICKED}
                element={InterfaceElementName.SWAP_BUTTON}
              >
                <ButtonError
                  onClick={() => {
                    //showPriceImpactWarning ? setShowPriceImpactModal(true) : handleContinueToReview()
                    handleContinueToReview()
                  }}
                  id="swap-button"
                  data-testid="swap-button"
                  //disabled={!isValid || routeIsSyncing || routeIsLoading}
                  disabled={!isBridgeOpen || !(hasSufficientBridgePool && hasSufficientBalance)}
                  error={isValid && priceImpactSeverity > 2 && allowance.state === AllowanceState.ALLOWED}
                >
                  {/*<Text fontSize={20} fontWeight={600}>*/}
                  {/*  {swapInputError ? (*/}
                  {/*    swapInputError*/}
                  {/*  ) : routeIsSyncing || routeIsLoading ? (*/}
                  {/*    <Trans>Deposit</Trans>*/}
                  {/*  ) : priceImpactSeverity > 2 ? (*/}
                  {/*    <Trans>Deposit</Trans>*/}
                  {/*  ) : (*/}
                  {/*    <Trans>Deposit</Trans>*/}
                  {/*  )}*/}
                  {/*</Text>*/}
                  <Text fontSize={20} fontWeight={600}>
                    {!hasSufficientBalance ? (
                      <Trans>Insufficient Balance</Trans>
                    ) : !hasSufficientBridgePool &&
                      ethers.utils.parseEther(parsedAmounts[Field.INPUT]?.toExact() ?? '0').gt(0) ? (
                      <Trans>Insufficient bridge pool capacity</Trans>
                    ) : (
                      <Trans>Deposit</Trans>
                    )}
                  </Text>
                </ButtonError>
              </TraceEvent>
            )}
          </div>
        </AutoColumn>
      </SwapWrapper>
    </>
  )
}
